import { DataTable } from "../components/DataTable";
import ServiceFilter from "../components/ServiceFilter";
import DateFormat from "../components/DateFormat";

const StatusDisplay = (status) =>{
    const colors = {
        'Aktiv': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-success-700 bg-success-500/10`}>Aktiv</span>,
        'Pause': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-primary-700 bg-primary-500/10`}>Pause</span>,
        'Ophørt': <span className={`inline-flex items-center justify-center min-h-6 px-2 py-0.5 text-sm font-medium tracking-tight rounded-xl whitespace-normal text-danger-700 bg-danger-500/10`}>Ophørt</span>

    }

    let color = colors[status];

    if(!color){
        color=<></>
    }
        
    
    return color;
}


const Services = () => {
    return (
      <DataTable
        searchable="client_name,name,service"
        filter={<ServiceFilter />}
        path="services?include=customer">
        <td
          row={(name) => <>{name}</>}
          field="client_name"
          header="Client"
          sortable
        />
        <td
          row={(service) => <>{service}</>}
          field="service_type"
          header="Service"
        />
        <td row={(name) => <>{name}</>} field="name" header="Name" sortable />
        <td row={DateFormat} field="end_date" header="End date" sortable />
        <td row={StatusDisplay} field="status" header="Status" />
        <td
          row={(service) => (
            <>
              {service?.price} {service?.currency?.toUpperCase()}
            </>
          )}
          header="Price"
          sortable
        />
        {/* <td row={(currency)=><>{currency}</>}  field="currency" header=""/> */}
      </DataTable>
    );
};


export default Services;